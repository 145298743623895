<template>
  <div>
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      :rules="rules"
      label-width="120px"
      class="mr10"
    >
      <b-card>
        <el-row>企业基础信息</el-row>
        <el-row :gutter="0">
          <el-col :span="4">
            <el-form-item label="企业LOGO">
              <img
                v-show="logoUrl"
                width="80"
                title="点击重新选择图片"
                class="img-fluid"
                :src="logoUrl"
                style="cursor: pointer"
                @click="uploadImgdialogVisible = true"
              >
              <el-button
                v-show="!logoUrl"
                size="small"
                @click="uploadImgdialogVisible = true"
              >
                上传图片
              </el-button>
              <input
                id="logoFile"
                accept="image/*"
                type="file"
                style="display: none"
                @change="fileSelect"
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="企业名称"
              prop="name"
              :rules="[ { required: true, message: '企业名称不能为空', trigger: 'change' }]"
            >
              <el-input
                v-model="form.name"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="企业简称"
              prop="shortName"
              :rules="[ { required: true, message: '企业简称不能为空', trigger: 'change' }]"
            >
              <el-input
                v-model="form.shortName"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="8">
            <el-form-item
              label="企业网站"
              prop="website"
              :rules="[ { required: true, message: '企业网站不能为空', trigger: 'change' }]"
            >
              <el-input
                v-model="form.website"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所在行业">
              <el-select
                v-model="form.industry"
                placeholder="请选择行业"
              >
                <el-option
                  v-for="(item,index) in industryOptions"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业规模">
              <el-select
                v-model="form.scale"
                placeholder="请选择企业规模"
              >
                <el-option
                  v-for="(item,index) in scaleOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="16">
            <el-form-item
              label="办公地址"
              prop="address"
              :rules="[ { required: true, message: '办公地址不能为空', trigger: 'change' }]"
            >
              <el-input
                v-model="form.address"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮编">
              <el-input
                v-model="form.zipCode"
                type="number"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item
            label="企业工商码"
            prop="code"
            :rules="[ { required: true, message: '企业工商码不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.code"
            />
          </el-form-item>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="8">
            <el-form-item
              label="联系人"
              :rules="[ { required: true, message: '联系人不能为空', trigger: 'change' }]"
              prop="contactName"
            >
              <el-input
                v-model="form.contactName"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="联系电话"
              prop="contactPhone"
            >
              <el-input
                v-model="form.contactPhone"
                placeholder="请输入手机号码或座机号码"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="联系邮箱"
              prop="contactEmail"
            >
              <el-input
                v-model="form.contactMail"
                type="email"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item
            label="备注"
            prop="remark"
          >
            <el-input
              v-model="form.remark"
              type="textarea"
            />
          </el-form-item>
        </el-row>
      </b-card>
      <b-card>
        <el-row>对接信息</el-row>
        <el-row :gutter="0">
          <el-col :span="12">
            <el-form-item
              label="对接人"
              prop="dockingPerson"
            >
              <el-input
                v-model="form.dockingPerson"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="对接人联系方式"
              prop="dockingPhone"
            >
              <el-input
                v-model="form.dockingPhone"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item
            label="备注信息"
            prop="dockingRemark"
          >
            <el-input
              v-model="form.dockingRemark"
              type="textarea"
            />
          </el-form-item>
        </el-row>
      </b-card>
    </el-form>
    <div class="dialog-footer text-center">
      <el-button @click="back">
        返 回
      </el-button>
      <el-button
        type="primary"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
    <el-dialog
      title="上传图片"
      :visible.sync="uploadImgdialogVisible"
      width="800px"
      @open="uploadImgDialogShow"
    >
      <el-button
        class="mb20"
        size="small"
        @click="logoFileClick"
      >
        选择图片
      </el-button>
      <div
        v-if="uploadImgdialogVisible"
        style="width:100%;height: 400px"
      >
        <vueCropper
          ref="cropper"
          :img="cropperOption.img"
          :output-size="cropperOption.size"
          :output-type="cropperOption.outputType"
          :can-scale="cropperOption.canScale"
          :auto-crop="cropperOption.autoCrop"
          :auto-crop-width="cropperOption.autoCropWidth"
          :auto-crop-height="cropperOption.autoCropHeight"
          :can-move-box="cropperOption.canMoveBox"
          :can-move="cropperOption.canMove"
          :center-box="cropperOption.centerBox"
          :info="cropperOption.info"
          :fixed-box="cropperOption.fixedBox"
        />
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="uploadImgdialogVisible = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="updateLogo"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  error, success, verifyEmali, verifyPhone,
} from '@core/utils/utils'
import {
  getSassEnterprise, getAutonomyEnterprise, saveSassEnterprise, saveAutonomyEnterprise, UploadLogo,
} from '@/api/enterprises/enterprises'

export default {
  data() {
    return {
      logoUrl: '',
      loading: false,
      scaleOptions: [
        { label: '1-100', value: 1 },
        { label: '101-1000', value: 2 },
        { label: '1001-10000', value: 3 },
        { label: '5000~10000', value: 4 },
        { label: '10000以上', value: 5 },
      ],
      industryOptions: ['互联网', '商业', '贸易', '制造业', '农业', '医疗', '物流', '安全'],
      form: {
        logoUrl: '',
        shortName: '',
        name: '',
        industry: '互联网',
        website: '',
        scale: 1,
        address: '',
        zipCode: '',
        code: '',
        contactName: '',
        contactPhone: '',
        contactMail: '',
        remark: '',
        dockingPerson: '',
        dockingPhone: '',
        dockingRemark: '',
      },
      rules: {
        contactMail: [
          { validator: verifyEmali, trigger: 'change' },
        ],
        contactPhone: [
          { required: true, message: '手机号码不能为空', trigger: 'change' },
          { validator: verifyPhone, trigger: 'change' },
        ],
      },
      uploadImgdialogVisible: false,
      cropperOption: {
        img: '',
        size: 200,
        outputType: 'png',
        outputSize: 1, // 裁剪生成图片质量
        canScale: true, // 图片是否允许滚轮播放
        autoCrop: true, // 是否默认生成截图框 false
        info: true, // 是否展示截图框信息
        infoTrue: false,
        // autoCropWidth: 200, // 生成截图框的宽度
        // autoCropHeight: 200, // 生成截图框的高度
        canMoveBox: true, // 截图框是否可以拖动
        fixedBox: false, // 固定截图框的大小
        canMove: true, // 上传图片是否可拖动
        centerBox: true, // 截图框限制在图片里面
      },
      type: '',
      fileBlob: null,
    }
  },
  created() {
    this.type = this.$route.query.type
    if (this.$route.query.id > 0) {
      this.getEnterpriseInfo()
    }
  },
  mounted() {
    this.navActiveInit(1, this.type === 'sass' ? 'SASS用户管理' : '独立部署管理', '企业管理')
  },
  destroyed() {
    this.navActiveInit(2, this.type === 'sass' ? 'SASS用户管理' : '独立部署管理', '企业管理', this.type === 'sass' ? '/sassenterprise' : '/enterprises')
  },
  methods: {
    updateLogo() {
      // 获取文件流
      this.$refs.cropper.getCropBlob(data => {
        this.fileBlob = data
      })
      // 获取base64
      this.$refs.cropper.getCropData(data => {
        this.logoUrl = data
      })
      this.uploadImgdialogVisible = false
    },
    transBase64FromImage(image) {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, image.width, image.height)
      return canvas.toDataURL('image/png')
    },
    uploadImgDialogShow() {
      const _this = this
      const image = new Image()
      image.src = `${this.logoUrl}?v=${Math.random()}`
      image.crossOrigin = '*'
      image.onload = function () {
        const base64 = _this.transBase64FromImage(image)
        _this.cropperOption.img = base64
      }
    },
    logoFileClick() {
      document.getElementById('logoFile').click()
    },
    fileSelect() {
      const uploadDom = document.getElementById('logoFile')
      const fileVal = uploadDom.files
      const that = this
      const file = fileVal[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function (e) {
        that.cropperOption.img = this.result
      }
    },
    back() {
      this.$router.push(this.type === 'sass' ? '/sassenterprise' : '/enterprises')
    },
    getEnterpriseInfo() {
      this.loading = true
      const apiName = this.type === 'sass' ? getSassEnterprise : getAutonomyEnterprise
      this.form.id = Number(this.$route.query.id)
      apiName({ id: this.form.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.loading = false
          Object.keys(this.form).forEach(key => {
            this.form[key] = resData.data[key]
          })
          this.logoUrl = window.g.baseUrl + this.form.logoUrl
        }
      })
    },
    saveEnterprise() {
      const apiName = this.type === 'sass' ? saveSassEnterprise : saveAutonomyEnterprise
      apiName(this.form).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          success(resData.msg)
          this.back()
        } else {
          error(resData.msg)
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.fileBlob) {
            const formData = new FormData()
            formData.append('file', this.fileBlob)
            UploadLogo(formData).then(result => {
              // this.form.logoUrl = result.data.data.accessPath
              this.form.logoUrl = result.data.data.savePath
              this.saveEnterprise()
            })
          } else {
            this.saveEnterprise()
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss">
.logo-img {
  width: 60px;
  position: absolute;
  border-radius: 10px;
}
</style>
